.footer {
  background: linear-gradient(180deg, #d9ffdf 0%, rgba(217, 255, 223, 0) 100%);
  padding: 80px 0px;
}
.main_sec {
  margin-top: 108px;
}
.office_locations {
  background-color: var(--color-green);
  padding: 60px 0px;
}
.copyrights {
  background-color: var(--color-brown);
  padding: 20px 0px;
  p {
    color: var(--color-white);
  }
}
.copyrights,
.privacy_links,
.office_locations {
  font-size: 16px;
}
.privacy_links {
  color: var(--color-white);
  a {
    font-weight: 500;
    color: var(--color-white);
  }
}
.office_locations {
  text-align: left;
  color: var(--color-white);
  font-weight: 500;
  p {
    line-height: 25px;
  }
  h6 {
    margin-bottom: 15px;
  }
}
.links_list {
  margin-top: 45px;
  h5 {
    font-weight: 600;
    margin-bottom: 35px;
  }
  li {
    font-size: 16px;
    color: #171717;
    font-weight: 500;
    margin-bottom: 25px;
  }
  li p {
    font-size: 14px;
    color: #0f0f0f;
    font-weight: normal;
  }
}
.follow {
  margin-top: 60px;
  h6 {
    margin-bottom: 20px;
  }
  img {
    width: 35px;
  }
}
.links_list {
  a:hover {
    color: var(--color-green);
  }
}
.hidden_text {
  visibility: hidden;
}
@media (max-width: 1199px) {
  .footer {
    padding: 60px 0px;
  }

  .col-lg-8,
  .col-xs-12 {
    padding: 0px;
  }

  .links_list li {
    margin-bottom: 15px;
  }
  .links_list h5 {
    margin-bottom: 20px;
  }
  .links_list,
  .follow {
    margin-top: 25px;
    text-align: left;
  }
  .office_locations h6 {
    margin-bottom: 10px;
  }
  .upper_leter {
    // margin-top: 30px;
  }
  .copyrights,
  .privacy_links,
  .office_locations {
    font-size: 15px;
  }
  .copyrights {
    .d-flex {
      display: block !important;
      text-align: left;
    }
    .privacy_links {
      margin-bottom: 15px;
    }
  }
  .links_list1,
  .links_list1 h5 {
    margin: 0px;
  }
  .links_list li p {
    font-size: 13px;
  }
}
@media (max-width: 575px) {
  .hidden_text {
    display: none;
  }
  .links_list {
    li {
      font-size: 14px;
    }
  }
  .links_list,
  .follow {
    margin-top: 35px;
  }
  .links_list1,
  .links_list1 h5 {
    margin-top: 0px;
  }
  .office_locations {
    .upper_leter {
      margin-top: 35px;
    }
    p {
      br {
        display: none;
      }
    }
  }
}
