body {
  margin: 0px;
  font-family: "Inter", sans-serif;
  font-weight: normal;
}
.row,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}
.row {
  text-align: left;
}
:root {
  --color-primary: #15a800;
  --color-secondary: #171717;
  --color-btns: #fff536;
  --color-white: #fff;
  --color-green: #16a02c;
  --color-btncolor: #096718;
  --color-sectionpara: #7c7c7c;
  --color-brown: #552d20;
  --color-border: #ececec;
  --color-lightgreen: #d7f3dd;
}
ul {
  padding-left: 0px;
  list-style: none;
}
.card {
  background: transparent;
  border: none;
  display: block;
}
a:hover {
  text-decoration: none;
}
header {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  background-color: var(--color-white);
  // padding: 15px 0px;
  box-shadow: 7px 12px 27px -3px rgba(0, 0, 0, 0.25);
}
.navbar {
  padding: 0px;
  padding: 15px 0px;
}
.navbar-brand {
  padding: 0px;
  margin: 0px;
}
.navbar-expand-lg .navbar-nav .nav-item a {
  padding: 0px;
  font-size: 12px;
  font-weight: 600;
  color: var(--color-secondary);
  // text-transform: uppercase;
}
.news_announcement {
  background-color: var(--color-btns);
  color: var(--color-green);
  font-size: 14px;
  font-weight: 500;
  a {
    color: var(--color-green);
  }
}
.navbar-expand-lg .navbar-nav .nav-item {
  margin-left: 60px;
}
.navbar-expand-lg .navbar-nav {
  align-items: center;
}
.navbar-light .navbar-nav .nav-item a:focus,
.navbar-light .navbar-nav .nav-item a:hover,
.navbar-light .navbar-nav .nav-item a.active {
  color: var(--color-green);
}
.navbar-light .navbar-nav .nav-item .dropdown-item:focus,
.navbar-light .navbar-nav .nav-item .dropdown-item:hover,
.navbar-light .navbar-nav .nav-item .dropdown-item.active {
  color: var(--color-secondary);
}
.navbar-brand :hover {
  cursor: pointer;
}
.closebtn_sec {
  display: none;
}
.menu-sec {
  display: flex;
  width: 100%;
  justify-content: inherit;
}
.navbar-toggler1 {
  display: none;
}

.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0px 120px;
}
// dropdown
.dropdown-menu {
  .row {
    width: 100%;
  }
}
.dropdown:hover {
  cursor: pointer;
}
.dropdown-slide > .dropdown-menu {
  opacity: 0.3;
  display: block;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transform: scale(1, 0);
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  border-radius: 0px;
  background-color: #fff;
  padding: 40px 30px;
  border: 0;
  box-shadow: 7px 12px 27px -3px rgba(0, 0, 0, 0.25);
}
.dropdown-slide > .dropdown-menu .dropdown-item:last-child {
  margin-bottom: 0px;
}
.dropdown-item {
  h6 {
    font-size: 16px;
    color: var(--color-green);
    font-weight: 600;
    letter-spacing: -0.5px;
    // font-family: "Roboto Condensed", sans-serif;
  }
  p {
    color: var(--color-secondary);
    font-size: 13px;
    font-weight: normal;
  }
  img {
    margin-right: 15px;
    width: 48px;
  }
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
  white-space: initial;
  padding: 10px 0px 10px 10px !important;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  top: 55px;
  min-width: 60rem;
  left: -300px;
}
.dropdown-menu:before {
  content: "";
  position: absolute;
  border-color: var(--color-btns);
  border: solid transparent;
  border-bottom-color: #fff;
  border-width: 15px;
  top: -18px;
  left: 33%;
  z-index: 1;
}
.dropdown-menu:after {
  content: "";
  position: absolute;
  left: 33%;
  top: -18px;
  width: 0;
  height: 0;
  border: solid transparent;
  border-width: 10px;
  border-bottom-color: #fff;
  z-index: 0;
}
.dropdown-item:hover {
  background-color: var(--color-lightgreen);
  border-radius: 18px;
}
.show.dropdown-slide > .dropdown-menu {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 1;
}
.error {
  color: red;
  font-size: 12px;
}
.banner_head {
  padding: 100px 0px;
  color: var(--color-white);
  h1 {
    font-size: 48px;
    line-height: 55px;
    font-weight: 700;
    font-family: "Roboto Condensed", sans-serif;
    letter-spacing: -2px;
  }
  p {
    font-weight: normal;
    margin: 15px 0px 35px;
    line-height: 26px;
  }
  h5 {
    font-size: 24px;
    font-weight: 600;
    font-family: "Roboto Condensed", sans-serif;
    letter-spacing: -1px;
  }
  h4 {
    margin-bottom: 25px;
    text-transform: uppercase;
  }
  button {
    margin-top: 25px;
  }
}
// common fonts
.home_text {
  font-size: 18px;
}
.body_para_main {
  font-size: 18px;
}
.primary_btn,
.secondry_btn {
  border: 0px;
  background-color: var(--color-green);
  padding: 10px 30px;
  border-radius: 18px;
  color: var(--color-white);
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}
.secondry_btn {
  background-color: var(--color-btns);
  color: var(--color-green);
}
.section_head {
  font-size: 44px;
  font-weight: 500;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: -1.5px;
  color: var(--color-btns);
}
.body_para {
  font-size: 16px;
  color: var(--color-white);
  font-weight: normal;
}
.upper_leter {
  text-transform: uppercase;
}
.common_row {
  margin-top: 60px;
}
.left_right_padding {
  padding: 0px 200px;
}
.banner_img_bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.special_text {
  color: var(--color-btns);
}
.banner_head {
  padding: 100px 0px;
  color: var(--color-white);
  h1 {
    font-size: 48px;
    line-height: 55px;
    font-weight: 700;
    font-family: "Roboto Condensed", sans-serif;
    letter-spacing: -2px;
  }
  p {
    font-weight: normal;
    margin: 15px 0px 35px;
    line-height: 26px;
  }
  h5 {
    font-size: 24px;
    font-weight: 600;
    font-family: "Roboto Condensed", sans-serif;
    letter-spacing: -1px;
  }
  h4 {
    margin-bottom: 25px;
    text-transform: uppercase;
  }
  button {
    margin-top: 25px;
  }
}
.green_bg {
  background-color: var(--color-green);
}
.banner_car .owl-item img {
  // width: auto !important;
  // padding: 40px 0px;
  display: flex;
  // margin: 0 auto;
}

.owl-carousel .owl-nav .owl-prev span,
.owl-carousel .owl-nav .owl-next span {
  display: none;
}
.banner_car .owl-nav {
  display: flex;
  position: absolute;
  bottom: 110px;
}
.banner_car .owl-dots {
  position: absolute;
  bottom: 70px;
  left: 170px;
}
.banner_car .owl-dots .owl-dot.active span,
.banner_car .owl-dots .owl-dot:hover span {
  background-color: var(--color-btns);
}
.banner_car .owl-dots .owl-dot span {
  background: #0b761c;
}

.p_cl1 {
  height: 220px;
}
.products_car .owl-item img {
  width: auto !important;
  display: flex;
  margin: 0 auto;
}
.products_car .owl-stage {
  display: flex;
  align-items: center;
}

// presence
.presence_row {
  background-color: var(--color-green);
  border-radius: 60px;
  padding: 0px 40px;
}
.numbers_coll {
  div {
    margin-bottom: 60px;
  }
}
.number_row {
  margin-top: 40px;
}
.customers {
  background-color: var(--color-brown);
  // border-radius: 60px;
  padding: 300px 0px 100px;
  margin-top: -300px;
}
.loc_customer {
  position: relative;
}
.stories_row {
  padding: 0px 100px;
  .sub_para {
    margin: 20px 0px 50px;
  }
}
.presence_row {
  position: relative;
}
.cust_car .owl-item img {
  width: auto !important;
  padding: 0px;
  margin: 0;
}
.cust_car .owl-nav {
  display: flex !important;
  bottom: 50px;
  width: 100%;
}
.cust_car .owl-nav button.owl-prev {
  left: 30px;
}
.cust_car .owl-nav button.owl-next {
  left: 100px;
}

@media (min-width: 1500px) {
  .banner_car .owl-dots {
    left: 235px;
    bottom: 130px;
  }
  .banner_car .owl-nav {
    left: 62px;
    bottom: 170px;
  }
  .banner_head h1 {
    font-size: 51px;
    line-height: 64px;
  }
  .banner_head h5 {
    font-size: 26px;
  }
  .products_car h4 {
    font-size: 20px;
  }
  .products_row {
    p {
      margin-bottom: 30px;
    }
    h5 {
      font-size: 16px;
    }
  }
  .products_car .owl-item.active.center h4 {
    font-size: 22px;
  }
  .products_row .card {
    padding: 40px 35px 0px;
  }
  .products_row p {
    line-height: 24px;
  }
  .cust_car .owl-nav {
    bottom: 60px;
  }
}
.banner_page {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  text-align: left;
  h4 {
    position: absolute;
    top: 30px;
  }
}
@media (max-width: 1199px) {
  .teams_row {
    display: none;
  }
  .banner_head h1 {
    font-size: 32px;
    line-height: 39px;
    letter-spacing: -1px;
  }
  .banner_head {
    padding: 40px 0px;
    p {
      line-height: 23px;
    }
    h5 {
      font-size: 18px;
      letter-spacing: 0px;
    }
  }
  .home_text {
    font-size: 16px;
  }
  .products_car .owl-item.active.center .card {
    padding: 40px 20px 0px;
  }
  .stories_row {
    padding: 0px;
  }
  .presence_row {
    border-radius: 20px;
    padding: 40px 20px;
  }
  .numbers_coll div {
    margin-bottom: 30px;
  }
  .stories_row {
    .col-lg-8 .d-flex {
      display: block !important;
    }
    .customer {
      margin-bottom: 20px !important;
    }
  }
  .section_head1_action {
    width: 100%;
    margin-bottom: 10px;
  }
  .market_names .final {
    height: auto;
    margin: 0px 20px 20px;
  }
  .customers {
    padding-bottom: 60px;
  }
}
@media (max-width: 575px) {
  .banner_car .owl-dots {
    bottom: 44.5%;
    left: 80px;
    margin-bottom: 15px;
  }
  .banner_car .owl-nav {
    bottom: 49%;
    margin-bottom: 15px;
  }
  .banner_car .owl-nav button.owl-prev {
    left: 20px;
  }
  .banner_car .owl-nav button.owl-next {
    left: 175px;
  }
  .banner_img_bg {
    // padding: 20px;
  }
  .banner {
    .banner_head {
      padding-bottom: 120px;
    }
  }
  .innerPageBanner .banner_head {
    padding-bottom: 40px;
  }
  .cust_car .owl-item .customer {
    margin: 0 auto;
  }
  .cust_car .owl-nav {
    width: 100%;
    bottom: 85%;
    right: auto;
  }
  .cust_car .owl-nav button.owl-next {
    right: -8px;
    left: auto;
  }
  .cust_car .owl-nav button.owl-prev {
    left: -10px;
  }
  .market_names {
    .card {
      width: 180px;
      border-radius: 20px;
      height: 110px;
    }
  }
  .team_mob,
  .market_names .row {
    display: inline-flex;
    width: 100%;
    flex-wrap: inherit;
    overflow-x: auto;
    justify-content: flex-start;
  }
  .market_names .final:first-child {
    margin-left: 0px;
  }
  .innerPageBanner .banner_head {
    padding-top: 10px;
  }
  .p_cl1 {
    height: 180px;
  }
}
// .owl-item {
//   height: 0;
// }
// .owl-item.active {
//   height: auto;
// }

// responsive
@media (max-width: 768px) {
  .navbar-toggler1 {
    display: block;
  }
  .menu-sec {
    left: 0px;
    bottom: 0px;
    height: 0px;
    background: var(--color-lightgreen);
    position: fixed;
    min-width: 100%;
    z-index: 20;
    transition: 0.5s;
    -webkit-transistion: 0.5s;
    margin-top: 0px;
    left: 0px;
  }
  .menu {
    height: 100%;
  }
  .closebtn_sec {
    display: block;
    position: absolute;
    top: 0;
    z-index: 999;
    width: 100%;
    padding: 10px 20px;
    background-color: var(--color-white);
  }
  .closebtn_sec .logo {
    width: 80px;
  }
  .closebtn {
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    color: #ffffff;
  }
}
.section_head1 {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--color-green);
  width: 40%;
  font-weight: 600;
}
.border_line {
  border-bottom: 1px solid var(--color-green);
  height: 1px;
  width: 100%;
}
.white_border {
  border-bottom: 1px solid var(--color-white);
}
.common_sec_padding {
  padding: 60px 0px;
}
.tree_bg .card p,
.sub_para {
  font-size: 18px;
  font-weight: normal;
  color: var(--color-btns);
  margin-top: 5px;
}
.tree_bg .card p {
  color: var(--color-secondary);
}
.collor_black {
  color: var(--color-secondary);
}
.head_line {
  margin-bottom: 30px;
}
.section_head1_action {
  width: 100%;
}
.text_justify {
  text-align: justify;
}
.common_padding {
  padding: 0px 100px;
}
.color_white,
.color_white:hover {
  color: var(--color-white);
}
.color_yellow {
  color: var(--color-btns);
}
.text_bold {
  font-weight: 600;
}
.contactpage_sec {
  padding-bottom: 0px;
}
.right_col_drop {
  img {
    width: 200px;
  }
}
@media (min-width: 1500px) {
  .container {
    padding: 0px 180px;
  }
  .body_para {
    font-size: 18px;
  }
  .sub_para {
    font-size: 20px;
  }
  .navbar-expand-lg .navbar-nav .nav-item a {
    font-size: 14px;
  }
  .section_head1 {
    font-size: 17px;
  }
  .section_head {
    font-size: 50px;
  }
  .primary_btn,
  .secondry_btn {
    padding: 15px 35px;
  }
}
@media (max-width: 1199px) {
  .container {
    padding: 0px 25px;
  }
  .cta_manimg {
    width: 280px;
    margin-bottom: 20px;
  }
  .border_line {
    width: 30%;
  }
  .navbar {
    padding: 15px 20px;
  }
  .body_para_main {
    font-size: 16px;
  }
  .primary_btn,
  .secondry_btn,
  .body_para {
    font-size: 15px;
  }
  .common_row {
    margin-top: 40px;
  }
  .section_head {
    font-size: 32px;
  }
  .section_head1 {
    width: 50%;
  }
  .tree_bg .card p,
  .sub_para {
    font-size: 16px;
  }
  .contactpage_sec {
    padding-bottom: 60px;
  }
}
@media (max-width: 991px) {
  .navbar-nav {
    padding: 90px 30px 20px;
    width: 100%;
    overflow-y: auto;
  }
  .navbar-nav1 {
    display: none;
  }
  .navbar-expand-lg .navbar-nav .nav-item {
    margin-left: 0px;
    border-bottom: 1px solid #949494;
    padding: 20px 0px;
    width: 100%;
    text-align: left;
  }
  .navbar-expand-lg .navbar-nav .nav-item a {
    margin-bottom: 0px;
    font-size: 14px;
  }
  .navbar-expand-lg .navbar-nav {
    align-items: flex-start;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    top: 55px;
    min-width: 0;
    left: -0;
  }
  .dropdown-slide > .dropdown-menu {
    background-color: transparent;
    box-shadow: none;
    padding: 0px;
    .col-lg-4 {
      padding: 0px;
    }
  }
  .dropdown-menu {
    display: none;
  }
  .dropdown-menu:after,
  .dropdown-menu:before,
  .right_col_drop {
    display: none;
  }
  .dropdown-item {
    border-bottom: 1px solid #949494;
    padding: 10px 0px !important;
  }
  .last_item {
    border-bottom: none;
    padding-bottom: 0px;
  }
  .about_scroll {
    overflow-x: hidden;
  }
  .banner_page h4 {
    position: initial;
  }
}
.swiper {
  width: 100% !important;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 350px !important;
  height: 400px !important;
}

.swiper-slide img {
  display: block;
  width: 100% !important;
}

button:hover {
  transform: scale(1.1);
}
button {
  transition: transform 0.5s;
}
