.contact_page {
  iframe {
    width: 580px !important;
    height: 380px !important;
    border: 0px !important;
  }
  .map_icon {
    width: 45px;
  }
}
.mob_col_map {
  h3 {
    font-size: 24px;
    color: var(--color-secondary);
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 500;
    letter-spacing: -0.5px;
  }
  p {
    font-size: 16px;
    color: var(--color-secondary);
    margin-top: 10px;
    font-weight: normal;
    padding-right: 120px;
  }
  .add_div {
    margin-bottom: 60px;
  }
  .mainDiv {
    padding-left: 60px;
  }
}
.social_media {
  img {
    margin-right: 10px;
    width: 30px;
  }
}

@media (max-width: 991px) {
  .contact_page iframe {
    width: 320px !important;
  }
  .mob_col_map {
    .mainDiv {
      padding-left: 0px;
      margin-top: 40px;
    }
    .add_div {
      margin-bottom: 25px;
    }
  }
}
.lendingTable {
  table {
    width: 100%;
    border-collapse: collapse;
    // border: 1px solid #7a7a7a;
    font-size: 16px;
    color: var(--color-secondary);
    letter-spacing: -0.3px;
  }
  th {
    border: 1px solid #7a7a7a;
    padding: 10px;
    text-align: left;
    width: 25%;
    font-weight: normal;
    border-top: none;
  }
  td {
    border: 1px solid #7a7a7a;
    padding: 10px;
    vertical-align: top;
  }
  .last_row {
    border-bottom: none;
  }
}
.avanthi_logo {
  width: 200px;
}
.table_row {
  border: 1px solid #7a7a7a;
  border-right: none;
}
@media (max-width: 575px) {
  .avanthi_logo {
    margin-bottom: 20px;
    width: 160px;
  }
  .lendingTable {
    padding: 0px;
    th {
      width: 18%;
    }
  }
  .table_row {
    border: none;
  }
  th {
    border-top: 1px solid #7a7a7a !important;
  }
  .last_row {
    border-bottom: 1px solid #7a7a7a !important;
  }
}
