.banner_img_bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.special_text {
  color: var(--color-btns);
}
.banner_head {
  padding: 100px 0px;
  color: var(--color-white);
  h1 {
    font-size: 48px;
    line-height: 55px;
    font-weight: 700;
    font-family: "Roboto Condensed", sans-serif;
    letter-spacing: -2px;
  }
  p {
    font-weight: normal;
    margin: 15px 0px 35px;
    line-height: 26px;
  }
  h5 {
    font-size: 24px;
    font-weight: 600;
    font-family: "Roboto Condensed", sans-serif;
    letter-spacing: -1px;
  }
  h4 {
    margin-bottom: 25px;
    text-transform: uppercase;
  }
  button {
    margin-top: 25px;
  }
}
.green_bg {
  background-color: var(--color-green);
}
.banner_car .owl-item img {
  // width: auto !important;
  // padding: 40px 0px;
  display: flex;
  // margin: 0 auto;
}
.banner_car .owl-nav button.owl-prev {
  background: url(../../assets/images/common/prev.svg) !important;
  left: 120px;
}
.banner_car .owl-nav button.owl-next {
  background: url(../../assets/images/common/next.svg) !important;
  left: 250px;
}
.products_car .owl-nav {
  display: block !important;
}
.products_car .owl-nav button.owl-prev {
  background: url(../../assets/images/common/next_v.svg) !important;
  left: -20px;
}
.products_car .owl-nav button.owl-next {
  background: url(../../assets/images/common/prev_w.svg) !important;
  right: -20px;
}

.banner_car .owl-nav button.owl-prev,
.banner_car .owl-nav button.owl-next,
.products_car .owl-nav button.owl-next,
.products_car .owl-nav button.owl-prev {
  position: absolute;
  background-size: 35px !important;
  width: 35px !important;
  height: 35px !important;
  background-repeat: no-repeat !important;
}
.products_car .owl-nav button.owl-next,
.products_car .owl-nav button.owl-prev {
  top: 45%;
}
.owl-carousel .owl-nav .owl-prev span,
.owl-carousel .owl-nav .owl-next span {
  display: none;
}
.banner_car .owl-nav {
  display: flex;
  position: absolute;
  bottom: 110px;
}
.banner_car .owl-dots {
  position: absolute;
  bottom: 70px;
  left: 170px;
}
.banner_car .owl-dots .owl-dot.active span,
.banner_car .owl-dots .owl-dot:hover span {
  background-color: var(--color-btns);
}
.banner_car .owl-dots .owl-dot span {
  background: #0b761c;
}

.products_row {
  margin: 40px 0px;
  //   padding: 0px;
  .card {
    border-radius: 20px;
    padding: 30px 20px 0px;
    border: 1px solid var(--color-border);
    background-color: var(--color-lightgreen);
  }
  h4 {
    font-size: 16px;
    color: var(--color-green);
  }
  p {
    color: var(--color-secondary);
    margin: 10px 0px 20px;
    line-height: 21px;
    height: 105px;
  }
  h5 {
    font-size: 14px;
    color: var(--color-secondary);
    margin-bottom: 30px;
  }
}
.p_cl1 {
  height: 220px;
}
.products_car .owl-item img {
  width: auto !important;
  display: flex;
  margin: 0 auto;
}
.products_car .owl-stage {
  display: flex;
  align-items: center;
}
.products_car .owl-item.active.center {
  img {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
    margin-top: 70px;
  }
  .card {
    background-color: var(--color-green);
    padding: 60px 30px 0px;
  }
  p {
    color: var(--color-white);
  }
  h4 {
    font-size: 18px;
    color: var(--color-white);
    font-weight: 600;
  }
  h5 {
    font-size: 16px;
    color: var(--color-btns);
  }
}

// presence
.presence_row {
  background-color: var(--color-green);
  border-radius: 60px;
  padding: 0px 40px;
}
.numbers_coll {
  div {
    margin-bottom: 60px;
  }
}
.number_row {
  margin-top: 40px;
}
.customers {
  background-color: var(--color-brown);
  // border-radius: 60px;
  padding: 300px 0px 100px;
  margin-top: -300px;
}
.loc_customer {
  position: relative;
}
.stories_row {
  padding: 0px 100px;
  .sub_para {
    margin: 20px 0px 50px;
  }
}
.presence_row {
  position: relative;
}
.cust_car .owl-item img {
  width: auto !important;
  padding: 0px;
  margin: 0;
}
.cust_car .owl-nav {
  display: flex !important;
  bottom: 50px;
  width: 100%;
}
.cust_car .owl-nav button.owl-prev {
  left: 30px;
}
.cust_car .owl-nav button.owl-next {
  left: 100px;
}
.market_names {
  margin-top: 60px;
  .row {
    justify-content: center;
  }
  .card {
    background-color: #411c10;
    border-radius: 40px;
    padding: 25px;
    margin-left: 20px;
    text-align: center;
  }
  .final {
    height: 125px;
  }
}
.teams_row {
  position: relative;
  margin-bottom: 50px;

  .appu,
  .murali,
  .jitesh,
  .si,
  .m_smile,
  .venkat,
  .s_smile2,
  .david,
  .mahesh,
  .lokesh,
  .s_smile1 {
    position: absolute;
  }
  .appu {
    top: -30px;
    left: 430px;
  }
  .center_img {
    display: flex;
    margin: 0 auto;
    padding: 150px 0px;
  }
  .murali {
    left: 180px;
    top: 100px;
  }
  .jitesh {
    right: 300px;
    top: 60px;
  }
  .si {
    top: -30px;
    right: 130px;
  }
  .m_smile {
    top: -50px;
    right: 30%;
  }
  .venkat {
    left: 350px;
    bottom: 0px;
  }
  .s_smile2 {
    left: 250px;
    bottom: 40px;
  }
  .david {
    right: 250px;
    bottom: 0px;
  }
  .mahesh {
    position: absolute;
    right: 80px;
    bottom: 180px;
  }
  .lokesh {
    left: 80px;
    bottom: 100px;
  }
  .s_smile1 {
    left: 80px;
    top: 60px;
  }
}

@media (min-width: 1500px) {
  .banner_car .owl-dots {
    left: 235px;
    bottom: 130px;
  }
  .banner_car .owl-nav {
    left: 62px;
    bottom: 170px;
  }
  .banner_head h1 {
    font-size: 51px;
    line-height: 64px;
  }
  .banner_head h5 {
    font-size: 26px;
  }
  .products_car h4 {
    font-size: 20px;
  }
  .products_row {
    p {
      margin-bottom: 30px;
    }
    h5 {
      font-size: 16px;
    }
  }
  .products_car .owl-item.active.center h4 {
    font-size: 22px;
  }
  .products_row .card {
    padding: 40px 35px 0px;
  }
  .products_row p {
    line-height: 24px;
  }
  .cust_car .owl-nav {
    bottom: 60px;
  }
}

@media (max-width: 1199px) {
  .teams_row {
    display: none;
  }
  .banner_head h1 {
    font-size: 32px;
    line-height: 39px;
    letter-spacing: -1px;
  }
  .banner_head {
    padding: 40px 0px;
    p {
      line-height: 23px;
    }
    h5 {
      font-size: 18px;
      letter-spacing: 0px;
    }
  }
  .home_text {
    font-size: 16px;
  }
  .products_car .owl-item.active.center .card {
    padding: 40px 20px 0px;
  }
  .stories_row {
    padding: 0px;
  }
  .presence_row {
    border-radius: 20px;
    padding: 40px 20px;
  }
  .numbers_coll div {
    margin-bottom: 30px;
  }
  .stories_row {
    .col-lg-8 .d-flex {
      display: block !important;
    }
    .customer {
      margin-bottom: 20px !important;
    }
  }
  .section_head1_action {
    width: 100%;
    margin-bottom: 10px;
  }
  .market_names .final {
    height: auto;
    margin: 0px 20px 20px;
  }
  .customers {
    padding-bottom: 60px;
  }
}
@media (max-width: 575px) {
  .banner_car .owl-dots {
    bottom: 44.5%;
    left: 80px;
  }
  .banner_car .owl-nav {
    bottom: 49%;
  }
  .banner_car .owl-nav button.owl-prev {
    left: 20px;
  }
  .banner_car .owl-nav button.owl-next {
    left: 175px;
  }
  .banner_img_bg {
  }
  .banner {
    .banner_head {
      padding-bottom: 100px;
    }
  }
  .innerPageBanner .banner_head {
    padding-bottom: 40px;
  }
  .cust_car .owl-item .customer {
    margin: 0 auto;
  }
  .cust_car .owl-nav {
    width: 100%;
    bottom: 85%;
    right: auto;
  }
  .cust_car .owl-nav button.owl-next {
    right: -8px;
    left: auto;
  }
  .cust_car .owl-nav button.owl-prev {
    left: -10px;
  }
  .market_names {
    .card {
      width: 180px;
      border-radius: 20px;
      height: 110px;
    }
  }
  .team_mob,
  .market_names .row {
    display: inline-flex;
    width: 100%;
    flex-wrap: inherit;
    overflow-x: auto;
    justify-content: flex-start;
  }
  .market_names .final:first-child {
    margin-left: 0px;
  }
  .innerPageBanner .banner_head {
    padding-top: 10px;
  }
  .p_cl1 {
    height: 180px;
  }
}
// .owl-item {
//   height: 0;
// }
// .owl-item.active {
//   height: auto;
// }
