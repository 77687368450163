.calltoaction_bg h5 a {
  color: #271712;
}
.contactpage_sec {
  // background-color: var(--color-primary);
}
.contactsec_row {
  margin-top: 30px;
}
.contactsec_row form {
  padding: 40px 30px;
  background-color: var(--color-white);
  border-radius: 30px;
}
.contactpara {
  display: flex;
  align-items: end;
  justify-content: center;
}
.contactpara {
  line-height: 17px;
}
.secondpara {
  line-height: 33px;
}
.contactsec_row form .form-control {
  background-color: var(--color-white);
  border-radius: 0px;
  padding: 20px 0px;
  border: 0px;
  border-bottom: 1px solid #7a7a7a;
  box-shadow: none !important;
}
.contactsec_row .form-control,
.contactsec_row input::placeholder,
.contactsec_row textarea::placeholder {
  color: #7a7a7a;
  font-size: 16px;
  font-weight: normal;
}
.contactsec_row form .form-control:focus,
.contactsec_row form .form-control:active {
  color: var(--color-secondry)!important;
}
.contactsec_row .form-group {
  margin-bottom: 30px;
}
.contactsec_row .submitbtn {
  background-color: var(--color-green);
  color: #fff;
  border: 0px;
  padding: 10px 40px;
  border-radius: 12px;
  font-size: 16px;
  
}
.contactsec_row .submitbtn1{
  width: 100%;
}
.thank-you {
  display: none;
}
